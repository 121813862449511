/* * {
    margin: 0;
    padding: 0;
    box-shadow: border-box;
} */

img {
    width: 100%;
    margin-bottom: -4px;
    /* display: none; */
}

.img-holder {
    /* width: 300px; */
    overflow: hidden;
    border: 2px solid #001C55;
}

.link {
    position: absolute;
    height: 100%;
    width: 100%;
    color: #fff;
    background: rgba(0,0,0,0.6);
    top: 0;
    padding: 30px 10px;
    font-size: 12px;
    text-align: center;
    display: none;
    transition: background 0.3s ease-in;
}

.project-description {
    width: 80%;
    margin: 0 auto;
}

.link {
    text-decoration: none;
}

.link p {
    margin-bottom: 10px;
}

.link a{
    color: #fff;
    /* margin-top: 100px; */
    font-size: 10px;
    text-decoration: none;
}

.project {
    width: 100%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
}

.project:hover .link {
    display: block;
    transition: background 0.3s ease-in;
}


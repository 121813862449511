.projects-spot {
    width: 80%;
    margin: 0 auto;
}

.projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}


.projects > div {
    flex: 1;
    margin-top: -50px;
    padding: 0;
}

h3 {
    font-weight: 400;
    text-align: center;
    margin-top: 40px;
}

@media(max-width: 850px) {
    .projects {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width: 500px) {
    .projects {
        grid-template-columns: repeat(1, 1fr);
    }

    .projects > div {
        margin-bottom: -40px;
    }
}